import React from 'react';
import './ErrorPages.css';

const ServerError = ({ errorCode, imagePath, errorMessage }) => {
  const openCrispChat = (e) => {
    e.preventDefault();
    if (typeof window.$crisp !== "undefined") {
      if (window.$crisp.is("chat:opened")) {
        window.$crisp.push(["do", "chat:close"]);
      } else {
        window.$crisp.push(["do", "chat:open"]);
      }
    } else {
      const script = document.createElement('script');
      script.src = 'https://client.crisp.chat/l.js';
      script.async = true;
      script.onload = () => {
        window.$crisp.push(["do", "chat:open"]);
      };
      document.head.appendChild(script);
    }
  };

  return (
    <div className="error-container">
      <img src={imagePath} alt="Error" className="error-image" />
      <h1>{errorCode}</h1>
      <p>{errorMessage}</p>
      <p className="support-message">If you need further assistance, please contact our support team.</p>
      <button className="support-button" onClick={openCrispChat}>Contact Support</button>
    </div>
  );
};

const Error500 = () => {
  return <ServerError errorCode="500 Server Error" imagePath="/assets/lightning.webp" errorMessage="Oops! Something may have happened on our end. Please try again later." />;
};

export default Error500;
