import React, { useState, useEffect } from 'react';
import './GameHosting.css';

const images = [
  'assets/datacenter.webp',
  'assets/gta-background.webp',
  'assets/minecraft-background.webp',
  'assets/ark-background.webp',
  'assets/rust-background.webp',
  'assets/arma3-background.webp',
  'assets/assetto_corsa-background.webp',
  'assets/dayz-background.webp',
  'assets/palworld-background.webp',
  'assets/redm-background.webp'
];

const gameData = [
  { title: 'FiveM', image: 'gta-background.webp' },
  { title: 'Minecraft: Bedrock', image: 'minecraft-background.webp' },
  { title: 'ARK: Survival Evolved', image: 'ark-background.webp' },
  { title: 'Rust', image: 'rust-background.webp' },
  { title: 'Arma 3', image: 'arma3-background.webp' },
  { title: 'DayZ', image: 'dayz-background.webp' },
  { title: 'RedM', image: 'redm-background.webp' },
  { title: 'Assetto Corsa', image: 'assetto_corsa-background.webp' },
  { title: 'Palworld', image: 'palworld-background.webp' }
];

const GamePage = () => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex(prevIndex => (prevIndex + 1) % images.length);
    }, 6000);

    return () => clearInterval(interval);
  }, []);

  return (
    <>
      <div className="game-page-container" style={{ backgroundImage: `url(${images[currentImageIndex]})` }}></div>
      <div className="game-page-new-container">
        <p><i className="fas fa-shield-alt"></i> DDoS protection</p>
        <p><img src="assets/amd.webp" alt="AMD Icon" className="game-specs-icon-image"/> AMD Ryzen 7950X</p>
        <p><i className="far fa-clock"></i> 24/7 online</p>
        <p><i className="fas fa-rocket"></i> Instant delivery</p>
        <p><i className="far fa-comments"></i> Great support</p>
      </div>
      <h1 className="container-header">Game Servers</h1>
      <div className="game-big-card-container">
        {gameData.map((data, index) => (
          <div key={index} className="game-card-with-header" style={{ backgroundImage: `url(assets/${data.image})`, backgroundSize: 'cover' }}>
            <div className="game-header-card">
              <h2 className="card-header">{data.title}</h2>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default GamePage;
