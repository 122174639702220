import React from 'react';
import './Footer.css';
import {
  FaTwitter,
  FaEnvelope,
  FaInfoCircle,
  FaShieldAlt,
  FaFileContract,
  FaYoutube,
  FaDiscord,
  FaGithub
} from 'react-icons/fa';
import { Link } from 'react-router-dom';

const Footer = () => {

  const toggleCrispChat = (e) => {
    e.preventDefault();

    if (typeof window.$crisp !== "undefined") {
      if (window.$crisp.is("chat:opened")) {
        window.$crisp.push(["do", "chat:close"]);
      } else {
        window.$crisp.push(["do", "chat:open"]);
      }
    } else {
      const script = document.createElement('script');
      script.src = 'https://client.crisp.chat/l.js';
      script.async = true;
      script.onload = () => {
        window.$crisp.push(["do", "chat:open"]);
      };
      document.head.appendChild(script);
    }
  };

  return (
    <footer className="footer">
      <div className="footer-container">
        <img src="assets/logo.webp" alt="Swiftynode Logo" className="footer-logo" loading="lazy" />
        <div className="footer-content">
          <ul className="footer-links">
            <li>
              <Link to="/about">
                <FaInfoCircle aria-label="About Us" /> About Us
              </Link>
            </li>
            <li>
              <Link to="/privacy">
                <FaShieldAlt aria-label="Privacy Policy" /> Privacy Policy
              </Link>
            </li>
            <li>
              <Link to="/terms">
                <FaFileContract aria-label="Terms of Service" /> Terms of Service
              </Link>
            </li>
            <li>
              <Link to="/" onClick={toggleCrispChat}>
                <FaEnvelope aria-label="Contact" /> Contact
              </Link>
            </li>
          </ul>
          <div className="footer-social-media">
            <a href="https://twitter.com" target="_blank" rel="noopener noreferrer" aria-label="Twitter"><FaTwitter alt="" /></a>
            <a href="https://discord.com" target="_blank" rel="noopener noreferrer" aria-label="Discord"><FaDiscord alt="" /></a>
            <a href="https://youtube.com" target="_blank" rel="noopener noreferrer" aria-label="YouTube"><FaYoutube alt="" /></a>
            <a href="https://github.com" target="_blank" rel="noopener noreferrer" aria-label="GitHub"><FaGithub alt="" /></a>
          </div>
        </div>
        <div className="footer-slogans">
          <p>Elevate your gaming experience with reliable, high-performance hosting tailored for every game.</p>
          <p>Whether you’re running an indie game or a large-scale community server, our solutions deliver speed, reliability, and 24/7 support. Designed to scale with your needs, our hosting services ensure smooth gameplay for everyone. Trust the platform thousands of gamers rely on to keep their games online and their communities connected.</p>
        </div>
      </div>
      <div className="footer-copyright">
        <p>&copy; 2024 SWIFTYNODE B.V. All rights reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;
