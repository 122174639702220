import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import Header from './Header/Header';
import Home from './Home/Home';
import Footer from './Footer/Footer';
import Dedicated from './Dedicated/Dedicated';
import GameHosting from './Game-Hosting/GameHosting';
import Forbidden from './ErrorPages/Forbidden';
import NotFound from './ErrorPages/NotFound';
import ServerError from './ErrorPages/ServerError';
import BadRequest from './ErrorPages/BadRequest';

const App = () => {
  const addSecurityHeaders = () => {
    const hstsHeader = 'max-age=31536000; includeSubDomains';
    const cspHeader = "default-src 'self'; script-src 'self' 'unsafe-inline' https://cdn.jsdelivr.net";
    const referrerPolicyHeader = 'no-referrer';
    const xContentTypeOptionsHeader = 'nosniff';
    const xFrameOptionsHeader = 'DENY';
  
    const headers = new Headers();
    headers.append('Strict-Transport-Security', hstsHeader);
    headers.append('Content-Security-Policy', cspHeader);
    headers.append('Referrer-Policy', referrerPolicyHeader);
    headers.append('X-Content-Type-Options', xContentTypeOptionsHeader);
    headers.append('X-Frame-Options', xFrameOptionsHeader);
  
    fetch('/', { method: 'GET', headers: headers });
  };
  
  addSecurityHeaders();

  return (
    <Router>
      <div className="App">
        <Header />
        <main>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/dedicated" element={<Dedicated />} />
            <Route path="/game-hosting" element={<GameHosting />} />

            <Route path="/403" element={<Forbidden />} />
            <Route path="/404" element={<NotFound />} />
            <Route path="/500" element={<ServerError />} />
            <Route path="/400" element={<BadRequest />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </main>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
