import React, { useState, useEffect } from 'react';
import './Dedicated.css';

const images = [
  'assets/datacenter.webp',
  'assets/gta-background.webp',
  'assets/minecraft-background.webp',
  'assets/ark-background.webp',
  'assets/rust-background.webp',
  'assets/arma3-background.webp',
  'assets/assetto_corsa-background.webp',
  'assets/dayz-background.webp',
  'assets/palworld-background.webp',
  'assets/redm-background.webp'
];

const cardData = [
  { title: '2x AMD Ryzen 5950X' },
  { title: '4x AMD Ryzen 5950X' },
  { title: '6x AMD Ryzen 5950X' },
  { title: '8x AMD Ryzen 5950X' },
  { title: '10x AMD Ryzen 5950X' },
  { title: '12x AMD Ryzen 5950X' },
  { title: '14x AMD Ryzen 5950X' },
  { title: '16x AMD Ryzen 5950X' }
];

const DedicatedPage = () => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex(prevIndex => (prevIndex + 1) % images.length);
    }, 6000);

    return () => clearInterval(interval);
  }, []);

  return (
    <>
      <div className="dedicated-page-container" style={{ backgroundImage: `url(${images[currentImageIndex]})` }}></div>
      <div className="dedicated-page-new-container">
        <p><i className="fas fa-shield-alt"></i> DDoS protection</p>
        <p><img src="assets/amd.webp" alt="AMD Icon" className="game-specs-icon-image"/> AMD Ryzen 7950X</p>
        <p><i className="far fa-clock"></i> 24/7 online</p>
        <p><i className="fas fa-rocket"></i> Instant delivery</p>
        <p><i className="far fa-comments"></i> Great support</p>
      </div>
      <h1 className="container-header">Dedicated Servers</h1>
      <div className="dedicated-big-card-container">
        {cardData.map((data, index) => (
          <div key={index} className="dedicated-card-with-header" style={{ backgroundImage: `url(assets/datacenter.webp)`, backgroundSize: 'cover' }}>
            <div className="dedicated-header-card">
              <h2 className="card-header">{data.title}</h2>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default DedicatedPage;
